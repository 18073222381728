const ServiceState = {
  CREATED: 'CREATED',
  INITIALIZING: 'INITIALIZING',
  OFFLINE: 'OFFLINE',
  CONNECTING: 'CONNECTING',
  ONLINE: 'ONLINE',
  AUTHENTICATING: 'AUTHENTICATING',
  READY: 'READY',
  ERROR: 'ERROR'
};

export default ServiceState;
